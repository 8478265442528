<script>
    export default {
        name: "Handoff",
        beforeMount() {
            if (this.direction === 1) {
                window.location = this.target;
            } else {
                this.$router.back();
            }
        },
        computed: {
            target() {
                return this.$store.getters['handoff/target'];
            },
            direction() {
                return this.$store.getters['handoff/direction'];
            }
        }
    }
</script>

<style scoped>

</style>
